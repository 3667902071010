import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { error } from 'src/app/_helpers/error';
import { FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-hospital-lists',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  message: string;
  eventLists: any = [];
  hospitalId: any;
  currentPage: number = 1;
  page: number;
  hasMore: boolean;
  totalCount: number;
  itemsPerPage: number = 10;
  type: Number = 3;
  passwordRestForm: any;
  submitted: boolean = false;
  notFound: boolean = false;
  searchedText: String;
  error = error.err;
  allHospitals: any = [];
  match: boolean = false;
  password: any;
  idChangePassword: any;
  DeviceList: any = [];


  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('template3') template3: TemplateRef<any>;
  constructor(
    private modalService: BsModalService,
    private _header: HeaderService,
    private toastrService: ToastrService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private dashboardService: DashboardService
  ) { }

  // header heading
  ngOnInit(): void {
    this._header.dashboardHeading.next(true);
    // this.initForm();

    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      // this.page = parseInt(params['page']);
      console.log('type of ', typeof paramPage, paramPage);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
      if (this.page) {
        this.currentPage = this.page;
      }
    });

    this.getEventDetails(this.page, this.itemsPerPage, '')

  }

  get f() {
    return this.passwordRestForm.controls;
  }

  ngOnDestroy() {
    this._header.dashboardHeading.next(false);
  }

  events = [];

  smallnumPages = 0;

  // delete modal
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  searchSubmit: boolean = false;

  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    if (!this.searchSubmit) {
      this.getEventDetails(event.page, event.itemsPerPage, "")
    }
    this.searchSubmit = false;

  }

  checkEmpty(event: any) {
    if (event.target.value == '') {
      // this.getEventLists(this.page, this.itemsPerPage);
      document.getElementById('search').blur();
      this.notFound = false;
    }
  }

  getEventDetails(page, pageLimit, searchKey) {
    this.dashboardService.getEventDetails({ "page": page, "pageLimit": pageLimit, "searchKey": searchKey }).subscribe((res: any) => {
      if (res.status == 'success') {
        this.eventLists = res.eventDataWithPagination;
        this.totalCount = res.totalCount;
        if (this.totalCount == 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }
      }
    })
  }

}
