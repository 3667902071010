

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ReportedPostsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getReportedPost(data: any) {
        return this.http.post(this.url + 'post/getreportedPosts', data);
    }

    getReportedPostbyId(data: any) {
        return this.http.post(this.url + 'post/getReportedPostbyId', data);
    }

    getPostbyId(data: any) {
        return this.http.post(this.url + 'post/getPostbyId', data);
    }

    deletePostById(data: any) {
        return this.http.post(this.url + 'post/deletePost', data);
    }

    changePostStatus(data: any) {
        return this.http.post(this.url + 'post/changePostStatus', data);
    }


}