import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AddSportsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }


    addSport(data:any){
        return this.http.post(this.url+'event/createSport', data);
    }

    getSports(data:any){
        return this.http.post(this.url+'event/getSports',data);
    }


    updateSports(data:any){
        return this.http.patch(this.url+'event/updateSport',data);
    }

    deleteSport(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'event/deleteSport', httpOptions);
    }
}