<div class="header">
    <div class="left-sec">
        <h1 *ngIf="dashboardHeading">Reporting</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="addSportsHeading">Sports</h1>
        <h1 *ngIf="reportedPostsHeading">Reported Posts</h1>
        <h1 *ngIf="reportedEventsHeading">Reported Events</h1>
        <h1 *ngIf="blockedUsersHeading">Users List</h1>


    </div>
    <!-- [checked]="viewer.isBlocked ==true" -->
    <div *ngIf="dashboardHeading" class="toggle-sec">
        <mat-card [ngClass]="{ 'red-card': status, 'green-card': !status }">
            <mat-slide-toggle [color]="status ? 'warn' : 'primary'" [checked]="status" style="margin-top: 6px;"
                (change)="changeToggleStatus($event)">
                <b style="color: blue;">App Under Maintenance</b>
            </mat-slide-toggle>
        </mat-card>
    </div>

    <div class="right-sec">
        <div class="userName">ADMIN</div>
    </div>
</div>