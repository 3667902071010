import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ReportedEventsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getReportedEvent(data: any) {
        return this.http.post(this.url + 'event/getreportedEvents', data);
    }

    getReportedEventbyId(data: any) {
        return this.http.post(this.url + 'event/getReportedEventById', data);
    }

    getEventbyId(data: any) {
        return this.http.post(this.url + 'event/getEventbyId', data);
    }

    deleteEventById(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'event/deleteEvent', httpOptions);
    }


}