import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getAnalytics(data: any) {
        return this.http.post(this.url + "user/analytics-report", data);
    }

    getTotalUserCount(data) {
        return this.http.post(this.url + "user/getTotalUserCount", data);
    }

    getEventDetails(data) {
        return this.http.post(this.url + "event/getEventDetailsforAdmin", data);
    }

}