import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  hide = true;
  hide1 = true;
  hide2 = true;
  message: string;
  currentUser: any;
  passChangeMsg: boolean = false;
  submitted: boolean = false;
  changed: boolean = false;

  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[\w !@#$%&*]/;
  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]),
  })

  get p() {
    return this.passwordForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // let currentUser = localStorage.getItem('currentUser');
    // this.userType = JSON.parse(currentUser).userType;
    this._header.changePasswordHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnDestroy() {
    this._header.changePasswordHeading.next(false);
  }

  match: boolean = true;
  changePassword() {
    this.changed = true;
    if (this.passwordForm.invalid || this.passwordForm.pristine) return;
    this.changed = false;
    let data = {
      "email": this.currentUser[0]?.email,
      "oldPassword": this.passwordForm.value.oldPassword,
      "newPassword": this.passwordForm.value.newPassword,
      userType: 1
    };
    if (this.passwordForm.value.newPassword == this.passwordForm.value.confirmPassword) {
      this.match = true;
      this.authService.changePassword(data).subscribe((res: any) => {
        console.log('changepassword res', res);
        if (res.status == 200) {
          this.passwordForm.controls['oldPassword'].reset();
          this.passwordForm.controls['newPassword'].reset();
          this.passwordForm.controls['confirmPassword'].reset();
          this.passChangeMsg = true;
          this.toastr.success(res.message);
          this.router.navigateByUrl('/auth/login');
        } else {
          this.toastr.error(res.error);
        }
      });
    } else {
      this.match = false;
    }
  }

  removeError() {
    this.changed = false;
    this.match = true;
  }
}
