<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
        <div class="total-counts">
            <div class="total-count total-users col-lg-5 mt-3 card-border" routerLink="/analytics/users">
                <img src="../../../assets/ride_loud_icons/Active users.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client" class="counter">{{clientCounts}}</h2>
                    <p>Total Number of Registered Users </p>
                </div>
            </div>

            <div class="total-count total-news  col-lg-5 mt-3 card-border" routerLink="/analytics/events">
                <img src="../../../assets/ride_loud_icons/Age Selection.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="caregiver" class="counter">{{eventCount}}</h2>
                    <p>Total Number of Events Created </p>
                </div>
            </div>
        </div>
    </div>
</div>