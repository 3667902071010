import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ThemePalette } from "@angular/material/core";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  dashboardHeading: boolean = false;
  changePasswordHeading: boolean = false;
  addSportsHeading: boolean = false;
  reportedPostsHeading: boolean = false;
  reportedEventsHeading: boolean = false;
  blockedUsersHeading: boolean = false;
  color: ThemePalette = 'primary';
  underMaintenance: any;
  status: any;


  constructor(
    private _header: HeaderService,
    public router: Router,
    private authService: AuthService,
    private sharedService: SharedService
  ) {

    this._header.addSportsHeading.subscribe((res) => {
      this.addSportsHeading = res;
    });

    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });

    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.reportedPostsHeading.subscribe((res) => {
      this.reportedPostsHeading = res;
    });

    this._header.reportedEventsHeading.subscribe((res) => {
      this.reportedEventsHeading = res;
    });

    this._header.blockedUsersHeading.subscribe((res) => {
      this.blockedUsersHeading = res;
    });
  }

  userName: String;
  allDate: any;
  currentUser: any;
  ngOnInit(): void {
    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase() + " " + this.allDate?.lastName?.toUpperCase();
    this.getToggleStatus();
  }

  changeToggleStatus($event) {
    this.sharedService.ChangeMaintenanceMode({ "id": this.underMaintenance?._id, isUnderMaintenance: $event.checked }).subscribe((res: any) => {
      if (res.status == 200) {
        this.getToggleStatus();
      }
    })
  }

  getToggleStatus() {
    this.sharedService.getMaintenanceMode({}).subscribe((res: any) => {
      if (res.status == 200) {
        this.underMaintenance = res.output;
        this.status = this.underMaintenance?.isUnderMaintenance;
      }
    })
  }
}
