import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { EventsListComponent } from "./dashboard/events-list/events-list.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "analytics",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      {
        path: "sports",
        loadChildren: () =>
          import("../app/views/add-sports/add-sports.module").then(
            (m) => m.AddSportsModule
          )
      },

      {
        path: "reported-posts",
        loadChildren: () =>
          import("../app/views/reported-posts/reported-posts.module").then(
            (m) => m.ReportedPostsModule
          )
      },
      {
        path: "reported-events",
        loadChildren: () =>
          import("../app/views/reported-events/reported-events.module").then(
            (m) => m.ReportedEventsModule
          )
      },
      {
        path: "reported-users",
        loadChildren: () =>
          import("../app/views/manage-viewer/manage-viewer.module").then(
            (m) => m.ManageViewerModule
          )
      },
      {
        path: "edit-profile",
        component: EditProfileComponent,
      },

      {
        path: "userEvents",
        component: EventsListComponent,
      },

    ],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
