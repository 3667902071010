import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {
  name: any;
  accessToken: any;
  _id: any;
  submitted: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      this.name = param.name.replace(/-/g, ' ');
      this._id = param.id;
      this.accessToken = param.token;
    })
  }

  changestatus(status) {
    this.authService.changestatus({ id: this._id, status: status }).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.submitted = true;
          if (res.output.status == 1) {
            this.toastr.success("Action already performed");
          }
        }
        else {
          this.toastr.error(res.error);
        }
      },
      (err) => {
        this.toastr.error(err.error);
      }
    );
  }

}


